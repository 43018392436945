import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from '../components/layout/SEO';
import Hero from '../components/home/Hero';
import About from '../components/home/About';
import Features from '../components/home/Features';
import Welcome from '../components/home/Welcome';
import Services from '../components/home/Services';
import Coverage from '../components/home/Coverage';
import Reviews from '../components/home/Reviews';

export default function IndexPage({
  data: { homepage, services, coords },
  location,
}) {
  return (
    <>
      <SEO title="Welcome" location={location.href} />

      <Hero
        topline={homepage.hero.topline}
        tagline={homepage.hero.tagline}
        buttonText={homepage.hero.button}
        image={homepage.hero.image.asset.url}
      />

      <About
        title={homepage.features.heading}
        tagline={homepage.features.tagline}
      />

      <Features />

      <Welcome
        title={homepage.welcome.heading}
        body={homepage.welcome.content.en}
        bullets={homepage.welcome.bullets}
        image={homepage.welcome.image.asset.url}
      />

      <Services services={services.nodes} />

      <Coverage
        title={homepage.map.heading}
        body={homepage.map.content}
        locale={homepage.map.locations}
        locations={coords.locations}
      />

      <Reviews
        heading={homepage.reviews.heading}
        body={homepage.reviews.body}
        reviews={homepage.reviews.reviews}
      />
    </>
  );
}

export const query = graphql`
  {
    homepage: sanityHomepage {
      hero {
        topline
        tagline
        button
        image {
          asset {
            url
          }
        }
      }
      features {
        heading
        tagline
      }
      welcome {
        heading
        content {
          en
        }
        bullets
        image {
          asset {
            url
          }
        }
      }
      map {
        heading
        content
        locations {
          name
          long
          lat
        }
      }
      reviews {
        heading
        body
        reviews {
          name
          company
          body
        }
      }
    }

    services: allSanityServices(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        slug {
          current
        }
        blurb {
          en
        }
        image {
          asset {
            url
          }
        }
      }
    }

    coords: allSanityCoordinates {
      locations: nodes {
        name
        latitude
        longitude
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};
